
import { mapGetters } from "vuex";
export default {
  name: "Pricing",
  data() {
    return {
      loading: "",
      pricing: [
        {
          name: "STARTER - 1000 TOKEN",
          price: 9.99,
          options: [
            `$0.01 per token`,
            `1 tokens will be deducted per order`,
            `Ger Real-time order notification`,
            `Change item name, price, etc any time`,
            `Dine-in & Take-out order receive`,
            `Sorting items, categories and feature categories any time`,
            `Monitoring waiter performance`,
            `Detailed Business report`,
            `Token never expire`,
          ],
        },
        {
          name: "STANDARD - 2500 TOKEN",
          price: 24.99,
          options: [
            `$0.01 per token`,
            `1 tokens will be deducted per order`,
            `Ger Real-time order notification`,
            `Change item name, price, etc any time`,
            `Dine-in & Take-out order receive`,
            `Sorting items, categories and feature categories any time`,
            `Monitoring waiter performance`,
            `Detailed Business report`,
            `Token never expire`,
          ],
        },
        {
          name: "Premium - 6000 TOKEN",
          price: 49.99,
          discount: 30,
          options: [
            `$0.008 per token`,
            `1 tokens will be deducted per order`,
            `Ger Real-time order notification`,
            `Change item name, price, etc any time`,
            `Dine-in & Take-out order receive`,
            `Sorting items, categories and feature categories any time`,
            `Monitoring waiter performance`,
            `Detailed Business report`,
            `Token never expire`,
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["manager"]),
  },
  methods: {
    async processPayment(pack) {
      try {
        this.click = false;
        await this.createCharge();
      } catch (error) {
        console.error("Error occurred:", error);
        this.$nuxt.$emit(
          "error",
          error.response?.data?.message || "Something wrong please try again"
        );
      } finally {
        this.loading = "";
        this.click = true;
      }
    },
    async createCharge() {
      try {
        const data = await this.$userApi.purchasePackage();
        console.log(data);
        // const { payment_url, vendor } = await this.$userApi.buyPackage();
        // return { payment_url, vendor: Number(vendor) };
      } catch (error) {
        console.error(error);
      }
    },
  },
};
