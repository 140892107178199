
import Wave from "~/static/images/wave.svg";
export default {
  name: "Contact",
  components: { Wave },
  data() {
    return {
      items: [
        {
          slug: "wagamama",
          image: "/images/demo/1.webp",
        },
        {
          slug: "theb_baked_potato_factory_58",
          image: "/images/demo/7.webp",
        },
        {
          slug: "european_bakery_105",
          image: "/images/demo/6.webp",
        },
        {
          slug: "gustelo_106",
          image: "/images/demo/5.webp",
        },
        {
          slug: "janan_restaurant_107",
          image: "/images/demo/4.webp",
        },
        {
          slug: "ice_berg_108",
          image: "/images/demo/3.webp",
        },
        {
          slug: "maritxu_109",
          image: "/images/demo/2.webp",
        },
      ],
    };
  },
};
