import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3fe1cc00&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=3fe1cc00&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fe1cc00",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeHeader: require('/vercel/path0/components/Home/Header.vue').default,HomeProcess: require('/vercel/path0/components/Home/Process.vue').default,HomeFeature: require('/vercel/path0/components/Home/Feature.vue').default,HomeFaq: require('/vercel/path0/components/Home/Faq.vue').default,HomeCustomer: require('/vercel/path0/components/Home/Customer.vue').default,HomePricing: require('/vercel/path0/components/Home/Pricing.vue').default,HomeContact: require('/vercel/path0/components/Home/Contact.vue').default})
