
import Wave from "~/static/images/wave.svg";
export default {
  name: "Contact",
  components: { Wave },
  data() {
    return {
      form: { name: "", email: "", message: "" },
      errors: {},
    };
  },
  methods: {
    async submit() {
      try {
        this.loading = true;
        this.errors = {};
        await this.$commonApi.requestContact(this.form);
        this.form = { name: "", email: "", message: "" };
        this.$nuxt.$emit("success", "We received your message successfully");
      } catch (error) {
        this.errors = error?.response?.data?.errors;
      } finally {
        this.loading = false;
      }
    },
  },
};
