
import { mapGetters } from "vuex";
export default {
  name: "indexPage",
  layout: "home",
  auth: false,
  head() {
    return { title: `Complete Digital Menu Solution - ${this.pageTitle}` };
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters(["isMobile", "pageTitle"]),
  },
};
